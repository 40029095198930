exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-code-experiments-js": () => import("./../../../src/pages/Code_Experiments.js" /* webpackChunkName: "component---src-pages-code-experiments-js" */),
  "component---src-pages-da-earthquakes-js": () => import("./../../../src/pages/DA-Earthquakes.js" /* webpackChunkName: "component---src-pages-da-earthquakes-js" */),
  "component---src-pages-different-worlds-js": () => import("./../../../src/pages/Different-Worlds.js" /* webpackChunkName: "component---src-pages-different-worlds-js" */),
  "component---src-pages-how-to-move-a-zoo-js": () => import("./../../../src/pages/How-to-Move-a-Zoo.js" /* webpackChunkName: "component---src-pages-how-to-move-a-zoo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kowloon-walled-city-js": () => import("./../../../src/pages/Kowloon-Walled-City.js" /* webpackChunkName: "component---src-pages-kowloon-walled-city-js" */),
  "component---src-pages-layered-horizons-js": () => import("./../../../src/pages/Layered-Horizons.js" /* webpackChunkName: "component---src-pages-layered-horizons-js" */),
  "component---src-pages-life-on-trappist-js": () => import("./../../../src/pages/Life-on-TRAPPIST.js" /* webpackChunkName: "component---src-pages-life-on-trappist-js" */),
  "component---src-pages-nga-js": () => import("./../../../src/pages/NGA.js" /* webpackChunkName: "component---src-pages-nga-js" */),
  "component---src-pages-obsessed-js": () => import("./../../../src/pages/Obsessed.js" /* webpackChunkName: "component---src-pages-obsessed-js" */),
  "component---src-pages-rna-uts-js": () => import("./../../../src/pages/RNA-UTS.js" /* webpackChunkName: "component---src-pages-rna-uts-js" */),
  "component---src-pages-smart-bins-js": () => import("./../../../src/pages/Smart-Bins.js" /* webpackChunkName: "component---src-pages-smart-bins-js" */),
  "component---src-pages-the-bloom-around-us-about-js": () => import("./../../../src/pages/The-Bloom-Around-Us-About.js" /* webpackChunkName: "component---src-pages-the-bloom-around-us-about-js" */),
  "component---src-pages-the-bloom-around-us-js": () => import("./../../../src/pages/The-Bloom-Around-Us.js" /* webpackChunkName: "component---src-pages-the-bloom-around-us-js" */),
  "component---src-pages-thesis-js": () => import("./../../../src/pages/Thesis.js" /* webpackChunkName: "component---src-pages-thesis-js" */),
  "component---src-pages-umai-dried-foods-js": () => import("./../../../src/pages/UMAI-Dried-Foods.js" /* webpackChunkName: "component---src-pages-umai-dried-foods-js" */)
}

